.OrganizationsParameters {
  max-width: 350px;
}

.OrganisationParameters__Modal-content {
  padding: 0;
}

.OrganisationParameters__Modal-content .header {
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    padding: 30px 38px 20px 38px;
    display: inline-block;
  }

.OrganisationParameters__Modal-content .header .OrganizationsParameters__select-actions {
      margin-top: 15px;
      width: 100%;
    }

.OrganisationParameters__Modal-content .header .OrganizationsParameters__select-actions button {
        width: 100%;
        padding: 0;
      }

.OrganisationParameters__Modal-content .body {
    padding: 14px 38px 30px 38px;
    background: #fafafa;
  }

.OrganisationParameters__Modal-content .body .OrganizationsParameters__toggle-all-button {
      margin-bottom: 10px;
      font-size: 12px;
      background: none;
      color: #2a90d5;
      padding-right: 10px;
      padding-left: 10px;
    }

.OrganisationParameters__Modal-content .body .OrganizationsParameters__toggle-all-button:hover {
        background: #f5f5f5;
      }

.OrganisationParameters__Modal-content .body .OrganizationsParameters__list {
      display: flex;
      flex-wrap: wrap;
      width: 550px;
      max-height: 50vh;
      max-height: 456px;
      overflow-y: scroll;
    }

.OrganisationParameters__Modal-content .body .OrganizationsParameters__list .OrganizationsParameters__item {
        width: 33%;
      }

.OrganisationParameters__Modal-content .body .OrganizationsParameters__actions {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
    }
