.Pagination__container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  font-family: Noway;
}

.Pagination {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.Pagination__item {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.Pagination__item:not(.Pagination__item--disabled):hover {
  background: rgba(0, 0, 0, 0.03);
}

.Pagination__item:not(.Pagination__item--disabled):active {
  background: rgba(0, 0, 0, 0.06);
}

.Pagination__item a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 0;
}

.Pagination__item svg {
  color: #2a90d5;
  margin-top: -2px;
}

.Pagination__item--active {
  font-weight: 500;
  color: #2a90d5;
}

.Pagination__item--disabled {
  color: #888;
}

.Pagination__item--disabled svg {
  color: #bebebe;
}

.Pagination__item--disabled a {
  cursor: unset;
}

.Pagination__status {
  margin-right: auto;
  display: flex;
  align-items: center;
  color: #7a7e80;
  font-size: 12px;
  font-family: Gotham;
}

.Pagination__status b {
  margin-left: 5px;
}

.Pagination__goto-label {
  margin-right: 11px;
  color: #000000;
}
