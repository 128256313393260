.AnalysisTable__container {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 80px);
}

.AnalysisTable-title {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 26px;
}

.AnalysisTable__side-menu {
  width: 250px;
  background: #fff;
  border-right: solid 1px #e5e5e5;
  padding: 32px 0;
  flex-shrink: 0;
}

.AnalysisTable__content {
  flex-grow: 1;
  padding: 32px;
  overflow-y: auto;
  margin: 0 auto;
}

.AnalysisTable__content h2 {
  margin-top: 0;
}

.AnalysisTable__side-button {
  width: 100%;
  height: 56px;
  padding: 0px 30px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-weight: bold;
}

.AnalysisTable__side-button:hover:not(.AnalysisTable__side-button--active) {
  background: rgb(250, 250, 250);
}

.AnalysisTable__side-button svg {
  margin-right: 8px;
}

.AnalysisTable__side-button--active {
  border-left: 7px solid rgb(97, 173, 224);
  background: rgb(233, 243, 252);
  color: rgb(97, 173, 224);
}
.analysis-status .fa {
  margin-right: 8px;
}

.dashboard-loading {
  margin: 20px auto;
  text-align: center;
}

div.analysis-list-header {
  height: 40px;
  border: solid 1px #e5e5e5;
  background-color: #f2f2f2;
  font-family: Gotham;
  font-size: 12px;
  font-weight: bold;
  color: #4a4c4c;
  display: flex;
  padding: 0 30px;
  align-items: center;
}

.analysis-list-header .analysis-item-name-wrapper {
  font-weight: 700;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.analysis-progress {
  flex: 1;
  margin-right: 5px;
}

.analysis-list-pagination {
  font-size: 13px;
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: none;
}

.analysis-list-empty {
  text-align: center;
  padding: 35px;
  background-color: #f9f9f9;
}

.analysis-list-empty .empty-title {
  color: #555;
  font-family: 'Gotham';
  font-weight: 400;
  text-transform: none;
  font-size: 24px;
  font-weight: bold;
}

.analysis-list-empty .empty-description {
  margin: 0;
  margin-top: 15px;
  font-size: 15px;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analysis-list-empty .empty-description svg {
  margin: 0 5px;
}

.analysis-list-empty .empty-description {
  margin-bottom: 16px;
}

.analysis-list-empty .button {
  margin-top: 25px;
}

.CircularProgressbar-path {
  stroke: #479fec;
}
.CircularProgressbar-trail {
  stroke: #e6e7de;
}

.analysis-list {
  position: relative;
}

.analysis-list-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  animation: appear 0.5s;
  animation-delay: 0.1s;
  animation-fill-mode: both;
  z-index: 1;
}

.pager-link--prev,
.pager-link--next {
  display: inline-flex;
  align-items: center;
  padding: 2px 0;
}

.DraftItem {
  background: #fff;
  height: 55px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  border: solid 1px #e5e5e5;
  margin-top: -1px;

  font-family: Noway;
  font-size: 14px;
  font-weight: 500;

  cursor: pointer;
}

.DraftItem:hover {
  background: #fbfbfb;
}

.DraftItem__type {
  font-size: 11px;
  color: #888;
  margin-bottom: 4px;
}

.DraftItem__name {
  width: 340px;
}

.DraftItem__date {
  font-family: Noway;
  font-size: 14px;
  font-weight: normal;
  margin-right: auto;
}

.DraftsList__header {
  height: 40px;
  border: solid 1px #e5e5e5;
  background-color: #f2f2f2;
  font-family: Gotham;
  font-size: 12px;
  font-weight: bold;
  color: #4a4c4c;
  display: flex;
  padding: 0 40px;
  align-items: center;
  margin-top: 40px;
}
.DraftsList__header > div:first-child {
  width: 340px;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.analysis-list-filters {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.analysis-list-filters .Input {
  width: 383px;
  margin-right: 24px;
}

@media (max-width: 800px) {
  .analysis-list-filters .Input {
    width: 100%;
  }
}

.analysis-list-filters .Input__field {
  height: 40px;
}

.analysis-list-filters .button-filters {
  width: 92px;
  height: 40px;
  border-color: #ddd;
}

@media (max-width: 800px) {
  .analysis-list-filters .button-filters {
    width: 100%;
  }
}

.analysis-list-filters .AnalysisTable__RadioGroup-wrapper {
  padding: 2px 3px 2px 3px;
  background-color: white;
  margin-left: 24px;
  border: solid 1px #ddd;
}

.analysis-list-filters
  .AnalysisTable__RadioGroup-wrapper
  .RadioGroup
  .RadioGroupItem {
  margin-right: 0;
}

.analysis-list-filters
  .AnalysisTable__RadioGroup-wrapper
  .RadioGroup
  .RadioGroupItem
  input:checked
  + label {
  border: none;
  color: #2a90d5;
  background-color: #e9f3fc;
}

@media (max-width: 800px) {
  .analysis-list-filters .AnalysisTable__RadioGroup-wrapper {
    height: 40px;
    margin-left: 0;
    width: 134px;
  }
}

@media (max-width: 800px) {
  .analysis-list-filters {
    display: grid;
    grid-columns: repeat(1, 1fr);
    gap: 10px;
    width: auto;
  }
}

.CircularProgressbar__text {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 500px) {
  .analysis-list-pagination {
    display: flex;
    flex-direction: column;
    height: unset;
    padding: 15px 0;
  }

  ul.pager {
    margin-left: 0;
    margin-top: 10px;
  }

  .reports-container {
    padding: 0;
  }

  .AnalysisTable-title,
  .analysis-list-filters {
    padding: 0 20px;
  }
}
