$FlatButton-padding-y: 0.325rem;
$FlatButton-padding-x: 0.825rem;
$FlatButton-border-radius: 3px;

$FlatButton-primary-color: #a1c200;
$FlatButton-secondary-color: #555;
$FlatButton-danger-color: #d9534f;
$FlatButton-warning-color: #f0ad4e;

$FlatButton-font-size: 0.96rem;
$FlatButton-font: '' Gotham '';
$line-height: 1.6;

.FlatButton {
  display: inline-block;
  border: none;
  padding: $FlatButton-padding-y $FlatButton-padding-x;
  line-height: $line-height;
  font-weight: 900;
  font-size: $FlatButton-font-size;
  font-family: $FlatButton-font;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 30px;
  text-decoration: none;
  user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  background-color: transparent;
  outline: 0;
}

.FlatButton.disabled,
.FlatButton:disabled,
fieldset[disabled] .FlatButton {
  opacity: 0.65;
  box-shadow: none;
  cursor: default;
}

.FlatButton + .FlatButton {
  margin-left: 4px;
}

.FlatButton-square {
  border-radius: 2px;
}

.FlatButton-primary {
  color: $FlatButton-primary-color;
}
.FlatButton-primary:not(:disabled):hover {
  background-color: transparentize($FlatButton-primary-color, 0.88);
}
.FlatButton-primary:not(:disabled):active {
  background-color: transparentize($FlatButton-primary-color, 0.75);
}

.FlatButton-secondary {
  color: $FlatButton-secondary-color;
}
.FlatButton-secondary:not(:disabled):hover {
  background-color: transparentize($FlatButton-secondary-color, 0.88);
}
.FlatButton-secondary:not(:disabled):active {
  background-color: transparentize($FlatButton-secondary-color, 0.75);
}

.FlatButton-danger {
  color: $FlatButton-danger-color;
}
.FlatButton-danger:not(:disabled):hover {
  background-color: transparentize($FlatButton-danger-color, 0.88);
}
.FlatButton-danger:not(:disabled):active {
  background-color: transparentize($FlatButton-danger-color, 0.75);
}

.FlatButton-warning {
  color: $FlatButton-warning-color;
}
.FlatButton-warning:not(:disabled):hover {
  background-color: transparentize($FlatButton-warning-color, 0.88);
}
.FlatButton-warning:not(:disabled):active {
  background-color: transparentize($FlatButton-warning-color, 0.75);
}
