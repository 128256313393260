.NavBar__menu-button {
  display: none;
  font-size: 20px;
  padding: 20px;
  margin-left: -10px;
  cursor: pointer;
  color: #888;
}

.NavBar__menu-button:hover {
  color: #333;
}

.NavBar__menu {
  display: flex;
  align-items: center;
}

.NavBar__right {
  display: flex;
  margin-left: auto;
}

.navbar-username {
  font-size: 0.8rem;
  color: #444;
}

.navbar-username .button-copy {
    text-transform: none;
    font-family: 'Gotham';
    font-weight: 400;
    font-size: 14px;
  }

.MenuItem {
  display: flex;
  align-items: center;
}

.MenuItem svg {
    margin-right: 8px;
    color: #6d6d6d;
  }

.NavBar__logo-link {
  cursor: pointer;
  margin-right: 21px;
}

@media (max-width: 1250px) {
  .NavBar__menu-button {
    display: block;
  }

  .NavBar__menu {
    display: none;
  }

  .NavBar__menu--open {
    animation: menuReveal 0.1s ease-in;
    animation-fill-mode: both;
    will-change: transform;
    position: absolute;
    left: 0;
    top: 80px;
    width: 100%;
    background-color: #444;
    display: flex;
    flex-direction: column;
    z-index: 99999;
  }

    .NavBar__menu--open .NavBarLink {
      width: 100%;
      padding: 0 20px;
      color: #fff;
    }

    .NavBar__menu--open .NavBarLink--active {
      color: #df1b12;
      box-shadow: inset 5px 0 0 0px #df1b12;
    }

    .NavBar__menu--open .NavBarLink:hover {
      box-shadow: inset 5px 0 0 0px #df1b12;
      background-color: #555;
    }

    .NavBar__menu--open .button {
      width: 100%;
      margin: 0;
      padding: 0 20px;
      background-color: unset;
      border: none;
      border-radius: 0;
      justify-content: flex-start;
      text-transform: uppercase;
      font-family: 'Gotham';
      height: 80px;
      line-height: 80px;
    }
    .NavBar__menu--open .button:hover {
      box-shadow: inset 5px 0 0 0px #df1b12;
      background-color: #555;
    }
}

@keyframes menuReveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 680px) {
  .button.NavBar__release-notes,
  .button.NavBar__user-guide {
    display: none;
  }
}

@media (max-width: 450px) {
  span[data-test-id='NavBar-Email'] {
    display: none;
  }

  .NavBar__logo-link {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    text-align: center;
  }

  .NavBar {
    padding: 0 10px;
  }
}
