.MapControls {
  z-index: 999;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #fff;
  padding: 12px 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  min-width: 150px;
}

.MapControls--title {
  margin-top: 0;
  margin-bottom: 8px;
}

@media (max-width: 550px) {
  .MapControls {
    display: none;
  }
}
