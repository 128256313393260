.MenuItem {
  padding: 10px 24px;
  font-size: 12px;
  color: #211c1d;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  min-width: 125px;
  box-sizing: border-box;
  cursor: pointer;
}

.MenuItem:first-child {
  border-radius: 3px 3px 0 0;
}
.MenuItem:last-child {
  border-radius: 0 0 3px 3px;
}

.MenuItem--danger {
  color: #e6212f;
}

.MenuItem:hover {
  background-color: #fafafa;
}
.MenuItem:active {
  background-color: #f4f4f4;
}
